<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <h3>請選擇當日上下哨</h3>
    </div>
    <form class="row flex-row mx-3" @submit.prevent="saveData">
      <!-- address -->
      <div class="col-12 text-start input-group">
        <label for="pet-select" style="font-size: 1.1rem">選擇地點</label>
        <select v-model="selected" class="" style="padding: 10px; font-size: 1.1rem; border-radius: 10px">
          <option v-for="(item, i) in data" :key="i" :value="item.na1name">{{ item.na1name }}</option>
        </select>
      </div>
      <div style="display: flex; text-align: center; align-items: center">
        <div
          @click="check_in()"
          class="col-6"
          style="border: 1px solid #ccc; border-radius: 10px; padding: 1rem; font-size: 1rem"
        >
          上哨
        </div>
        <div
          @click="check_out()"
          class="col-6"
          style="border: 1px solid #ccc; border-radius: 10px; padding: 1rem; margin: 5px; font-size: 1rem"
        >
          下哨
        </div>
      </div>
      <!-- <div class="col-12 my-2">
        <button type="submit" class="btn btn-primary my-1 w-100 py-2">
          儲存
        </button>
      </div>
      <div class="col-12">
        <router-link to="/chooseProject">
          <button type="button" class="btn btn-outline-primary w-100 py-2">
            取消
          </button>
        </router-link>
      </div> -->
    </form>
  </div>
  <footer></footer>
</template>
<script>
import { onMounted, ref } from '@vue/runtime-core';
import { toRefs, reactive } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import { computed } from '@vue/runtime-core';
import { useStore } from 'vuex';
//import axios from 'axios';
//import moment from "moment";
export default {
  name: 'ChooseProject2',
  setup() {
    const router = useRouter();
    //const route = useRoute();
    const data = ref(null);
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    let { us1no } = toRefs(user.value);
    // const submit = ref(false);
    const form = reactive({
      na2name: null,
      selected: '',
    });
    const { na2name, selected } = toRefs(form);
    //const selected = "";
    //---------------------------------
    async function getData() {
      const res = await fetch(`${process.env.VUE_APP_BASE_URL}type=GETNA2`).catch((err) => console.log(err));
      const result = await res.json();
      //console.log(result.data);
      na2name.value = result.data.na1name;
      if (result.data) {
        return (data.value = result.data);
      }
    }
    onMounted(async () => {
      await getData();
    });
    const proxyData = new Proxy(data, {
      get(target, key, receiver) {
        console.log(target);
        //...（先作省略）執行把依賴儲存起來的程式碼

        let result = Reflect.get(target, key, receiver);
        return result;
      },
      set(target, key, value, receiver) {
        // 以下會回傳布林值
        let bol = Reflect.set(target, key, value, receiver);

        //...（先作省略）執行所有依賴，更新所有受這依賴影響的值
        // set handler 規定要回傳 true
        return bol;
      },
    });
    //-----------------------------------
    async function check_in() {
      // await getData();
      // //console.log(selected._object.selected);
      // //console.log(us1no._object.us1no);
      // const res = await fetch(
      //   `${process.env.VUE_APP_BASE_URL}type=ADDPS2&na2name=${selected._object.selected}&pe1no=${us1no._object.us1no}`
      // ).catch((err) => console.log(err));
      //   const result = await res.json();
      //   console.log(result);
      //   if (result.success) {
      //     alert("✔ 儲存成功");
      //     submit.value = true; //stop onBeforeRouteLeave
      //     router.push("/");
      //   } else {
      //     alert(`⚠ ${result.error} ⚠`);
      //   }
      if (selected.value === '') {
        alert('⚠ 打卡地點不得為空');
        return;
      }
      router.push(`/reportForm2/${selected._object.selected}/${'A'}`);
    }
    //-----------------------------------
    async function check_out() {
      // await getData();
      // //console.log(selected._object.selected);
      // //console.log(us1no._object.us1no);
      // const res = await fetch(
      //   `${process.env.VUE_APP_BASE_URL}type=ADDPS22&na2name=${selected._object.selected}&pe1no=${us1no._object.us1no}`
      // ).catch((err) => console.log(err));
      // const result = await res.json();
      // console.log(result);
      // if (result.success) {
      //   alert('✔ 儲存成功');
      //   submit.value = true; //stop onBeforeRouteLeave
      //   router.push('/');
      // } else {
      //   alert(`⚠ ${result.error} ⚠`);
      // }
      if (selected.value === '') {
        alert('⚠ 打卡地點不得為空');
        return;
      }
      router.push(`/reportForm2/${selected._object.selected}/${'B'}`);
    }
    return {
      getData,
      data,
      check_in,
      check_out,
      selected,
      proxyData,
      us1no,
    };
  },
};
</script>
<style scoped>
::placeholder {
  color: var(--input-line);
}
.input-group {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
}
label {
  margin-right: 10px;
}
h5 {
  white-space: nowrap;
}
h5:nth-child(2),
h5 > input {
  font-weight: 600;
  color: var(--first-color);
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}
h5 > input {
  font-size: 20px;
  text-align: center;
}
footer {
  /* for mobile keyboard */
  height: 50vh;
}
</style>
